@use 'src/styles/common.scss';

.container {
  h3 {
    margin-right: auto !important;
    margin-left: auto !important;
    text-transform: capitalize;
  }
  .id {
    position: absolute;
    left: 20px;
    top: 20px;
  }
  .count {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .level {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .image {
    img {
      width: 200px;

      &.unknown {
        @extend .unknown;
      }

      &.found {
        @extend .found;
      }

      &.captured {
        @extend .captured;
      }
    }
  }
}
