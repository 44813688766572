@use 'src/styles/common.scss';

.modal {
  z-index: 1;
  overflow: auto;
  margin-bottom: 0px;
  max-width: 100%;
  width: 100%;

  .container {
    @extend .row;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1024px;
    margin: 0 auto;
    gap: 10px;
    align-items: center;

    & > div {
      @extend .column;
      align-items: center;
    }

    img {
      width: 42px;
    }

    button {
      @extend .primary-button;
    }
  }
}
