@use 'nes.css/css/nes.css';

.button {
  @extend .nes-btn;
  border-image-repeat: stretch;

  &:disabled {
    @extend .is-disabled;
  }
}

.primary-button {
  @extend .button;
  @extend .is-primary;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.addition {
  background-color: #edffe3;
}

.subtraction {
  background-color: #e3f1ff;
}

.multiplication {
  background-color: #ffe6e3ff;
}

.division {
  background-color: #feffe3ff;
}

.unknown {
  filter: contrast(0) blur(2px);
  opacity: 0.5;
}

.found {
  filter: contrast(0.3) blur(2px);
  opacity: 0.3;
}

.captured {
  filter: none;
  opacity: 1;
}
