@use 'nes.css/css/nes.css';
@use 'src/styles/common.scss';

.forgotten-password {
  margin-top: 1rem;
  margin-bottom: 1rem;

  a {
    text-decoration: underline;
  }
}

.buttons {
  display: flex;
  gap: 20px;

  .send {
    @extend .primary-button;
  }

  .google {
    @extend .nes-icon;
    @extend .google;
    @extend .is-small;
  }

  .facebook {
    @extend .nes-icon;
    @extend .facebook;
    @extend .is-small;
  }
}
