@use 'src/styles/common.scss';

.container {
  @extend .column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .pokemonName {
    text-transform: capitalize;
  }

  .pokemons {
    @extend .row;
    width: 100%;
    gap: 30px;
    justify-content: center;
    background-image: url('~/public/backgrounds/forest.jpg');
    background-size: cover;
    background-position: 0 -200px;

    progress {
      background-color: transparent;

      &::-webkit-progress-value {
        background: green;
      }

      &::-webkit-progress-bar {
        background: transparent;
      }
    }

    .player,
    .opponent {
      img {
        width: 200px;
      }
    }
  }

  .send {
    @extend .primary-button;
  }

  .actions {
    @extend .column;
    align-items: center;

    .buttons {
      @extend .column;
      gap: 10px;
    }
  }
}
