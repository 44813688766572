@use 'nes.css/css/nes.css';
@use 'src/styles/common.scss';

.menu-button {
  @extend .button;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;

  &.current {
    @extend .is-success;
  }

  img {
    width: 46px;
    height: 46px;
  }

  span {
    width: 100%;
  }
}
