@use 'nes.css/css/nes.css';

.container {
  @extend .nes-container;
  margin: 10px 10px;

  .with-title {
    @extend .with-title;
  }

  .title {
    @extend .title;
  }
}
