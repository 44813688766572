@use 'nes.css/css/nes.css';
@use 'src/styles/common.scss';

.container {
  height: 100%;
  display: flex;
  align-items: center;

  h1 {
    text-align: center;
    a {
      text-decoration: none;
      color: #f03030;
    }
  }
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
