@use 'src/styles/common.scss';

.container {
  margin-bottom: 100px;

  .header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: white;
    border-bottom: 5px solid black;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;

    .side-panel-toggle {
      img {
        width: 32px;
      }
    }

    a {
      text-decoration: none;
      color: #f03030;

      &:hover {
        text-decoration: none;
        color: #f03030;
      }
    }

    h1 {
      img {
        width: 48px;
      }

      span {
        @media (max-width: 600px) {
          display: none;
        }
      }
    }
  }

  .pokemon-image {
    object-fit: cover;
    object-view-box: inset(53% 0% 0% 0%);
  }

  .row {
    @extend .row;
  }
}

.account {
  width: 100%;
}
