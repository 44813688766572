@use 'nes.css/css/nes.css';

.container {
  max-width: 400px;
  margin: 0 auto;

  div.speech {
    @extend .nes-balloon;
    @extend .from-left;
    max-width: 500px;
    text-align: left;
    border-image-repeat: stretch;
  }

  i {
    @extend .nes-ash;
    align-self: flex-end;
  }
}
