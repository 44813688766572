body {
  &.noscroll {
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

hr {
  border: 2px solid black;
  margin-top: 15px;
  margin-bottom: 15px;
}
