@use 'src/styles/common.scss';

.container {
  text-align: center;

  .quiz {
    @extend .row;

    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .evaluate {
    @extend .primary-button;
  }
}
