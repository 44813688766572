@use 'src/styles/common.scss';

.container {
  @extend .column;

  .stats {
    @extend .row;
    justify-content: space-around;
    flex-wrap: wrap;

    div {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  input {
    margin-top: 10px;
  }
}
