@use 'nes.css/css/nes.css';
@use 'src/styles/common.scss';

.container {
  @extend .nes-container;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  & > a {
    @extend .button;
    margin: 10px;
  }
}
