@use 'src/styles/common.scss';

.container {
  text-align: center;
  .grid {
    @extend .row;

    flex-wrap: wrap;
    justify-content: center;
  }
}
